@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: #ffffff;
}

html,
body,
#root {
  height: 100%;
}

.ant-menu-item-selected {
  background-color: transparent !important;
  color: white !important;
}

.ant-menu-item-selected a {
  color: white !important;
}

.content-container {
  overflow-y: auto;
  height: calc(100vh - 64px);
}

/* Custom CSS for Tabs */
.custom-tabs .ant-tabs-nav .ant-tabs-tab {
  /* color: #7D4CDB !important; */
}
.custom-tabs .ant-tabs-nav .ant-tabs-tab:hover {
  color: #7d4cdb !important;
}

.custom-tabs .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #7d4cdb !important;
}

.custom-tabs .ant-tabs-ink-bar {
  background: #7d4cdb !important;
}

.ant-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ant-card-head-title {
  font-size: 1.5rem;
  color: #7d4cdb;
}

.ant-divider {
  margin: 16px 0;
}

.ant-form-item-label > label {
  color: #7d4cdb;
}

.ant-input {
  border-radius: 4px;
}

.ant-btn-primary {
  background-color: #7d4cdb;
  border-color: #7d4cdb;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #6933b9;
  border-color: #6933b9;
}

/* index.css or your main CSS file */
.blurry-background {
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
}

.catalog-container {
  padding: 20px;
  background: linear-gradient(to bottom, #f0f2f5, #001628);
}

.catalog-title {
  margin-bottom: 20px;
}

.catalog-row {
  margin-top: 20px;
}

.catalog-col {
  display: flex;
  justify-content: center;
}

.agent-card {
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.agent-header {
  margin-bottom: 16px;
}

.agent-name {
  margin-bottom: 0;
}

.agent-role {
  font-size: 14px;
  color: #555;
}

.agent-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.agent-image {
  width: 100px;
  border-radius: 50%;
}

.agent-tasks {
  background-color: #001628;
  color: white;
  padding: 16px;
  border-radius: 0 0 16px 16px;
  width: 100%;
}

.agent-task {
  display: block;
  margin-bottom: 8px;
  color: white;
}

/* src/pages/PricingPage.css */
.pricing-container {
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.pricing-header .highlight {
  color: #7d4cdb;
}

.toggle-buttons .toggle-button {
  background-color: #f0f2f5;
  color: #000;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}

.toggle-buttons .toggle-button.active {
  background-color: #000;
  color: #fff;
}

.pricing-card {
  border-radius: 1rem;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pricing-card.highlighted {
  border: 2px solid #1890ff;
}

.pricing-card-header .plan-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.pricing-card-header .plan-title.highlighted {
  color: #1890ff;
}

.popular-badge {
  background-color: #ffeb3b;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #000;
}

.price {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1rem;
}

.price .per-shot {
  font-size: 1rem;
  color: #888;
}

.features-list {
  margin-top: 1.5rem;
}

.feature-item {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.buy-now-button {
  background-color: #000;
  border-color: #000;
  color: #fff;
  height: 3rem;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 0.5rem;
}

.buy-now-button.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}

.active-link {
  background-color: #e5e7eb; /* Tailwind's gray-200 */
  color: #1f2937; /* Tailwind's gray-800 */
}

/* custom styling for select component */
.language-select .ant-select-selector {
  border: none !important; /* Removes the border */
  padding-right: 2px !important; 
}

.language-select .ant-select-selection-item {
  min-width: 60px !important;
}

.language-select .ant-select-arrow {
  margin-right: -8px; /* Adjusts the position of the arrow for minimal spacing */
}

.language-select .ant-select-dropdown{
  width: 60px !important;
}
/* src/components/GlobalModal.css */
/* .blurred-content {
    filter: blur(5px);
    text-align: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 4px;
  } */


.blurred-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.blurred-content {
  text-align: center;
  margin-top: 20px;
}